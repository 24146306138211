﻿.pois {
    .tips {
        text-align: center;
        color: #47ACF0;
        border: 2px solid #47ACF0;
        display: block !important;
        max-width: 260px;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 20px;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .details {
        margin-top: 10px;
    }

    .busted {
        background-color: #1077ba;
        opacity: 0.8;
        height: 200px;
        border-radius: 3px;
    }

    .bustedtext:after {
        color: white;
        font-size: 180%;
        content: "#busted";
        font-weight: bold;
        position: relative;
        bottom: 120px;
    }


    .notbusted {
    }

    .notbustedVideoLink {
        color: white;
        font-size: 180%;
        /*content: "\f04b";*/
        font-weight: bold;
        position: relative;
        bottom: -75px;
    }

    .notbustedVideoLink:after {
        color: white;
        font-size: 180%;
        /*content: "\f04b";*/
        font-weight: bold;
        position: relative;
        bottom: -75px;
    }

    .notbustedVideoLink:hover {
        cursor: pointer;
    }
}
