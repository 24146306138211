﻿.umbraco-forms-form .checkboxlist label{
    float:none !important;
    margin-left:10px;
    display:block;
    position:relative;
    bottom:25px;
    left:25px;
}


.umbraco-forms-field{
    margin-top:10px;
}

.form-control-borderless {
    border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}