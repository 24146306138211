﻿.sidebar {
    color: #fff;
    padding: 0;
    margin-bottom: 30px;

    @media(min-width: $lg-screen) {
        width: 340px;
        padding-left: 15px;
        padding-top: 30px;
    }

    .btn {
        border-radius: 0;
        width: 100%;

        @media(min-width: $lg-screen) {
            display: none;
        }
    }

    .dropdown-toggle {
        &:after {
            display: none;
        }
    }

    @media(min-width: $lg-screen) {
        .collapse {
            display: block;
        }
    }

    ul {
        padding: 0;

        li {
            list-style: none;
            padding: 7px 10px;
            border-bottom: solid 2px #e0e0e0;

            a {
                color: #000;
            }

            &.primary {
                font-weight: bold;
                font-size: 18px;
            }

            &.secondary {
                padding-left: 22px;

                &.active a {
                    font-weight: bold;
                    color: #1077BA;
                }
            }
        }
    }
}


.customSB {
    a {
        color: #000;
        padding: 7px 10px;
        border-bottom: solid 2px #e0e0e0;

        @media(min-width: $lg-screen) {
            width: 340px;
            padding-left: 15px;
        }

        .btn {
            border-radius: 0;
            width: 100%;

            @media(min-width: $lg-screen) {
                display: none;
            }
        }

        .dropdown-toggle {
            &:after {
                display: none;
            }
        }

        @media(min-width: $lg-screen) {
            .collapse {
                display: block;
            }
        }

        &.primary {
            font-weight: bold;
            font-size: 18px;
        }

        &.secondary {
            padding-left: 22px;


            &.active {
                font-weight: bold;
                color: #1077BA;
            }
        }
    }
}

