﻿@import "_variables.scss";


a {
    text-decoration: underline;
    color:#0054AD; 
}

.kcpdFooterContact a {
    color: #0A5485 !important;
}

body {
    font-family: "Lato", sans-serif;
}

h1, h2 {
    margin-top: 10px;
    margin-bottom: 30px;
    border-bottom: 4px black solid;
}

h1, h2, h3 {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

main {
    @media (min-width: $lg-screen) {
        margin-top: 100px;
        min-height: calc(100vh - 281px);
    }
}

.page-wrapper {
    /*display: flex;
    flex-direction: column;
    align-content: stretch; commented out by crooney to fix IE issue (content overlapping footer)*/

    @media (min-width: $lg-screen) {
        min-height: calc(100vh - 281px);
    }
}

.content-wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex: 1;
    flex-direction: column;
     
    @media (min-width: $lg-screen) { flex-direction: row; }
}

.flex-grow { flex-grow: 1; }

.p-30 {
    @media (min-width: $lg-screen) {
        padding-top: 30px;
    }
}

.headerline {
    width: 100%;
    border-bottom: solid 2px #121724;
    margin-bottom: 25px;
}

.headerline h3 {
    font-size: 1.5rem;
}

.flex-grow {
    flex: 1;
}

.mb-none {
    margin-bottom: 0;
}

section {
    margin-bottom: 30px;
}

.btn-pd-ltblue {
    border: solid 1px #17a3eb;
    background: #fff;
    text-transform: uppercase;
    color: #17a3eb;
    font-weight: bold;
}

.btn-pd-ltblue:hover,
.btn-pd-ltblue:focus {
    background: #17a3eb;
    color: #fff;
}

.carousel-item h2 {
    font-size: 3.5rem;
    text-transform: none;
    color: #fff;
}

.hl-section {
    background-color: #f5f6f7;
}

.section-banner {
    height: 250px;
    overflow: hidden;
    background: radial-gradient(rgba(0,0,0,0.85), rgba(0,0,0,0.65));


    img {
        width: 100%;
        opacity: .7;

        @media (max-width: $md-screen) {
            position: relative;
            top: 50%;
        }
    }

    h1 {
        position: absolute;
        top: 180px;
        width: 100%;
        text-align: center;
        color: #fff;
        border: 0;
        font-size: 3vw;

        @media (max-width: $md-screen) {
            font-size: 5.4vw;
        }
    }
}

.eventListDate {
    font-weight: bold;
    border-bottom: solid 1px #000;
    margin-bottom: 5px;
}

@media (min-width: $lg-screen) {
    .carousel-item {
        height: 633px;
        background-size: cover;
        background-position: center;
    }

    .carousel-caption {
        position: initial;
    }
}

.allowForNav {
    margin-top: 185px;
}


footer {
    background-color: #F5F6F7;
    padding: 10px 0;
}

.kcpdFooterLogo, .kcpdFooterContact {
    width: 100%;
    text-align: center;
    float: left;

    @media(min-width: $md-screen) {
        width: auto;
    }
}

    .kcpdFooterContact {
        @media(min-width: $md-screen) {
            margin-left: 20px;
        }
    }

    .foooterSponsors {
        font-size: .8rem;
        text-align: center;

        @media(min-width: $md-screen) {
            font-size: 1rem;
        }

        @media(min-width: $lg-screen) {
            text-align: right;
        }

        .reserved {
            display: block;

            @media(min-width: $md-screen) {
                display: initial;
            }
        }
    }

    .foooterSponsors, .kcpdFooterContact, .smHolder {
        margin-top: 40px;
    }

    .smHolder {
        font-size: 20px;
    }

    .fa-twitter {
        margin-left: 5px
    }

    .fa-facebook-f {
        margin-right: 5px
    }

.newsletter {
    .card {
        border: 0px;
    }

    .card-header {
        background-color: transparent;
        border: 0px;
        padding:0px;
    }

    .card-body {
        padding-left: 40px !important;
    }

    h2{margin-bottom:0px;}
}

.DivisionWatchImg{
    max-height:200px;
}
