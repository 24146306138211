﻿.navbar-dark {
    .navbar-nav {
        height: 100vh;
        width: 100vw;
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        overflow-x: hidden; /* Disable horizontal scroll */
        background: #0f1322;
        padding: 15px 15px 0 15px;

        @media(min-width: $lg-screen) {
            left: auto;
            right: 0;
            width: 50vw;
            flex-direction: column;
        }

        @media(min-width:$xl-screen) {
            width: 100%;
            height: auto;
            position: inherit;
            z-index: auto;
            left: auto;
            top: auto;
            overflow-x: auto;
            padding: 0;
            background: transparent;
            flex-direction: row;
        }

        .nav-link {
            color: #ffffff;
            background: transparent;
            border: 0;
            text-align: left;

            @media(min-width:$xl-screen) {
                width: 135px;
                text-align: center;
            }
        }

        .nav-search {
            .search-text {
                display: inline-block;
                padding-left: 5px;
            }

            @media(min-width: $xl-screen) {
                .nav-link {
                    width: 50px;
                }

                .search-text {
                    display: none;
                }
            }
        }
    }
}

.close-menu {
    padding-bottom: 10px;
    border-bottom: solid 1px #fff;
    margin-bottom: 10px;

    @media(min-width: $xl-screen) { display: none; }

    .text {
        color: #fff;
        font-size: 25px;
        font-weight: bold;
        float: left;
    }

    button {
        float: right;
        
        .fa-times {
            color: #fff;
            font-size: 25px;
        }
    }

    button:after {
        clear:both;
    }
}

.emergency-numbers {
    color: #fff;
    background-color: #6f717a;
    border-radius: 5px;
    padding: 7px;
    margin-top: 20px;
    font-weight: bold;

    @media(min-width: $xl-screen) {
        display: none;
    }
}

.navWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 104px;
    background-color: #0f1322;
    transition: background-color .3s linear;
    z-index: 1029;
}

.navbar-brand {
    margin-right: 0;

    img {
        display: inline-block;
        vertical-align: baseline;
        height: 65px;
    }
}

.nav-department {
    display: inline-block;
    position: relative;
    top: -10px;
    padding-left: 10px;
    font-weight: bold;
    font-size: 1rem;

    @media(min-width:$lg-screen){ font-size: inherit; }
}

.navbar-toggler {
    .fa-bars {
        color: #fff;
        font-size: 1.7rem;
    }
}

.top-nav {
    width: 100%;

    @media(min-width: $xl-screen) {
        width: 900px;
    }

    .top-numbers {
        a {
            color: #fff;
        }

        a:hover {
            text-decoration: none;
            color: #fff;
        }

        background-color: #55555E;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 0;
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
        font-size: .8rem;

        &.hide {
            display: none;

            @media(min-width: $md-screen) {
                display: inherit;
            }
        }

        @media(min-width: $md-screen) {
            font-size: 1rem;
        }

        span {
            display: block;

            @media(min-width: $md-screen) {
                display: inline-block;
            }
        }

        .top-number-divider {
            display: none;

            @media(min-width: $md-screen) {
                display: inline-block;
                padding: 0 15px;
            }
        }
    }
}

/* Mega Menu */
.mega-dropdown-toggle {
    width: 100%;

    &:after {
        font-family: 'Font Awesome 5 Free';
        content: "\f0d7";
        font-weight: 900;
        padding-left: 5px;
    }

    &:focus {
        color: #fff;

        @media(min-width: $xl-screen) {
            background: #6f717a;
        }
    }
}

.mega-dropdown {
    position: static;
    width: 100%;
}

.mega-dropdown-menu {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;

    @media(min-width:$xl-screen) {
        padding: 2rem 0;
    }

    ul {
        list-style: none;
        width: 100%;

        &.row {
            display: inherit;
            flex-wrap: nowrap;
            padding: 0;

            @media(min-width: $xl-screen) {
                margin: 0 auto;
                -webkit-column-count: 4;
                -webkit-column-gap: 15px;
                -moz-column-count: 4;
                -moz-column-gap: 15px;
                column-count: 4;
                column-gap: 15px;
            }
        }

        li {
            &.col {
                @media(min-width: $xl-screen) {
                    display: block;
                    padding: 0px 15px;
                    word-wrap: break-word;
                    margin-bottom: 15px;
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;
                }
            }

            .w-100 {
                margin-bottom: 10px;
            }
        }
    }
}

.mega-submenu > li > a {
    color: #fff;
}


.dropdown:hover > .dropdown-menu {
    border-top: 0;

    @media(min-width: $xl-screen) {
        display: block;
    }
}

.dropdown > .dropdown-toggle:active {
    pointer-events: none;
}

.navbar-nav .dropdown-menu {
    top: 102px;
    background: #6f717a;
    color: #fff;
    border-radius: 0;
}

.navbar-dark .navbar-nav .nav-item:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;

    .nav-link {
        color: #fff;
    }

    @media(min-width: $xl-screen) {
        background: #6f717a;
    }
}

.dropdown-item {
    color: #fff;
    font-weight: bold;
}

.navbar-nav > li.dropdown.show .dropdown-menu {
    display: flex;
}