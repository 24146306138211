﻿.homePageMain {
    position: relative;

    @media(min-width: $lg-screen) {
        top: -100px;
    }
}

.homePageHero {
    /*background: radial-gradient( rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.55) );*/
    width: 100%;
    height: 500px;
    padding: 200px 10px 0 10px;
    background-repeat: no-repeat;
    display: block;
    background-position: 50% 20%;
    background-size: cover;
    position: relative;

    @media(min-width: $lg-screen) {
        height: 700px;
        padding: 0;
    }
}

.heroText {
    text-align: center;
    color: #ffffff;
    width: 100%;
    font-size: 40px;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 60px;
    line-height: 1.2;

    @media(min-width:$md-screen) {
        max-width: 700px;
        font-size: 50px;
    }

    @media(min-width:$lg-screen) {
        padding-top: 16%;
        max-width: 650px;
        font-size: 90px;
    }

    @media(min-width: $xl-screen) {
        padding-top: 8%;
    }
}

.heroImg {
    height: 410px;
    width: 100%;
}


.caseImage {
    margin-bottom: 10px;
}

.caseholder:after {
    content: "";
    display: block;
    border-bottom: 2px solid #85898c;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

.caseHeader {
    font-weight: bold;
    margin-bottom: 10px;
}

.mpThumbImage {
    height: 185px;
    width: 145px;
}

.missingPHeader {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 30px;
}

.missingPH1 {
    margin-top: 130px;
    margin-bottom: 30px;
}

.iframeHolder {
    margin: auto;
    width: 80%;
}

.contentDesc {
    margin-bottom: 40px;
}

.missingrow {
    margin-bottom: 40px;
}

#hpLearnMore {
    text-align: center;
    color: #ffffff;
    border: 2px solid #ffffff;
    display: block !important;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: bold;
}

#hpLearnMore:hover {
    text-decoration: none;
}

.homeColdCase {
    color: #ffffff;
    margin-bottom: 0;

    h1 {
        border-bottom: 0px;
        font-size: 2.2rem
    }

    .hCCImage {
        background-color: #242E49;
        padding: 20px 0;
        text-align: center;

        img {
            width: 60%;
            max-width: 100%;

            @media(min-width: $md-screen) {
                width: 85%;
            }

            @media(min-width: $lg-screen) {
                width: 50%;
            }
        }
    }

    .hCCText {
        background-color: #121724;
        padding: 20px 10%;
    }

    .hCCButton {
        text-align: center;
        color: #47ACF0;
        border: 2px solid #47ACF0;
        display: block !important;
        max-width: 260px;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 20px;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.home-quickLinks {
    background: #131723;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 20px 0;
    margin-bottom: 20px;

    .col-6 {
        margin-bottom: 1.5rem;

        @media(min-width: $md-screen) {
            margin-bottom: 0;
        }
    }

    .fas, .far {
        font-size: 30px;
    }

    a {
        color: #ffffff;
    }
}

.home-news-events {
    background-image: url(/images/skyline.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    padding-bottom: 70px;
    margin-bottom: 0;

    img {
        margin-bottom: 10px;
        max-width: 100%;
    }

    .dt {
        font-size: .8rem;
    }

    h4, h5, p {
        color: #121724;
        text-decoration: underline;
        text-decoration-color: #121724;
    }

    .home-latest-news {
        .short-desc {
            display: none;

            @media (min-width: $lg-screen) {
                display: block;
            }
        }
    }

    .home-side-news {
        @media (min-width: $lg-screen) {
            img {
                width: 100%;
                max-width: 225px;
            }
        }
    }

    .home-event {
        margin-bottom: 20px;
        font-size: .9rem;
        border-bottom: dotted 1px #d7d7d7;
        padding-bottom: 20px;
        color: #121724;
        text-decoration: underline;

        &:last-child {
            border: 0;
        }

        .event-time {
            margin-bottom: 8px;
        }

        .event-title {
            font-weight: bold;
            font-size: 1.1rem;
        }
    }
}

.home-jobs img {
    width: 100%;
    max-width: 700px;
}

.home-services {
    padding: 25px 0;
}

.home-social {
    padding-bottom: 15px;
    background-image: linear-gradient(#F1F1F1, #f5f6f7);
}

/* Twitter Feed */
.twitter-container {
    margin-bottom: 20px;

    @media(min-width: $lg-screen) {
        margin-bottom: 0;
    }
}

#twitter-feed {
    width: 100%;
    margin: 0 auto;
    border: solid 1px #d7d7d7;
    background: #fff;
    padding: 5px;
    overflow-y: scroll;
    height: 500px;
}

#twitter-feed .tweet-content a {
    color: #4375C8;
    text-decoration: underline;
}

#twitter-feed ul {
    padding: 0;
}

#twitter-feed ul li {
    list-style: none;
}

#twitter-feed .tweet-author .Avatar {
    width: 48px;
    border-radius: 50%;
}

#twitter-feed .tweet-author .TweetAuthor-avatar {
    display: inline-block;
}

#twitter-feed .tweet-author .TweetAuthor {
    display: inline-block;
}

#twitter-feed .tweet-author .TweetAuthor .TweetAuthor-nameScreenNameContainer {
    margin-top: 10px;
}

#twitter-feed .tweet-author .TweetAuthor .TweetAuthor-nameScreenNameContainer .TweetAuthor-verifiedBadge {
    display: none;
}

#twitter-feed .tweet-author .TweetAuthor .TweetAuthor-name {
    display: block;
    position: relative;
    top: 10px;
    color: #000;
    font-weight: bold;
}

#twitter-feed .tweet-author .TweetAuthor .TweetAuthor-screenName {
    display: block;
    position: relative;
    top: 10px;
    font-size: .9em;
    color: #657786;
}

#twitter-feed .tweet {
    margin-left: 48px;
    font-size: 14px;
}

#twitter-feed .tweet .tweet-img {
    margin-bottom: 10px;
}

#twitter-feed .tweet .tweet-img img {
    width: 100%;
}

@media (min-width: 990px) {
    #twitter-feed .tweet-author .Avatar {
        width: 64px;
    }

    #twitter-feed .tweet {
        margin-left: 64px;
    }

    #twitter-feed .tweet .tweet-img img {
        width: auto;
        max-height: 200px;
    }
}
/* Facebook Feed */
.facebook-container {
    .facebook-frame {
        height: 500px;
    }
}

#facebook_iframe {
    border: 0;
}
/* Slick Slider */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: none;
}


.slick-prev {
    left: 0;

    @media(min-width: $md-screen) {
        left: -35px;
    }
}

.slick-next {
    right: 0;

    @media(min-width: $md-screen) {
        right: -35px;
    }
}


.slick-prev:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f104";
    font-size: 3rem;
}

.slick-next:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-size: 3rem;
}

.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
}

.slick-dots li {
    margin: 0 0.25rem;
}

.slick-dots button {
    display: block;
    width: .8rem;
    height: .8rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #b7b7b7;
    text-indent: -9999px;
}

.slick-dots li.slick-active button {
    background-color: #17a3eb;
}

.home-services-slider {
    .slick-slide {
        padding: 0 20px;

        img {
            margin-left: 39%;
        }
    }
}

.home-stations {
    padding: 20px 0;

    a {
        color: black;
    }

    button {
        color: #0A5180;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;

        &.collapsed {
            color: #2b2b2b;
        }

        .fas {
            padding-left: 10px;
        }
    }

    .station-community-officer {
        background: #1077ba;
        color: #fff;
        padding: 8px 15px;
        font-size: .9rem;

        @media(min-width: $md-screen) {
            font-size: 1rem;
        }
    }

    .modal-footer button {
        color: #ffffff;
    }

    .cioHolder {
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        margin-bottom: 10px;
    }
}

.map-container {
    height: 450px;
}

#map {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.card-body {
    padding: .75rem !important;
}

.serviceIcon {
    font-size: 3em;
    color: black;
    font-weight: 600;
}

a .serviceIcon:hover {
    text-decoration: none;
}

.mapHolder {
    background: radial-gradient( rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.55)), url('../../images/kcpdMap.jpg') no-repeat;
    height: 500px;
    border-radius: 5px;
    padding: 30px;
}

.mapTextLink {
    background-color: #ffffff;
    color: #1077ba;
    border: 3px solid #1077ba;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 475px;
    height: 80px;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 135%;

    @media screen and (max-width: 500px) {
        font-size: 135%;
        padding: 10px;
        width: 200px;
        height: 125px;
    }
}

.home-services {
    h4 {
        font-size: 1.2rem;
        text-decoration: underline;
        color: #5D5D5E;
        font-weight: bold;
    }

    .desktopOnly {
        display: initial;

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }

    .mobileOnly {
        display: none;

        @media screen and (max-width: 1000px) {
            display: initial;
        }
    }
}

.rewardMoney {
    /*font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;*/
    /*font-family:'Yu Gothic';
    font-family:'MS Gothic';
    font-family:'Century Gothic';*/
    font-family: "Lato", sans-serif;
    font-size: 25px;
    text-decoration: none;
    border-bottom: 0px;
}

.youTubeLink {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}

.youTubeLink iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}